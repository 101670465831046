export const quizOptionsObject: Record<string, string[]> = {
  //EUROPE
  "WestEurope": ["France", "Italy", "Spain", "Portugal"],
  "NorthEurope": ["UK", "Ireland", "Sweden", "Denmark", "Finland", "Norway", "Netherlands"],
  "CentralEurope": ["Germany", "Poland", "Slovakia", "Slovenia", "Czech Republic", "Austria", "Belgium", "Luxembourg", "Switzerland"],
  "NorthEastEurope": ["Moldova", "Ukraine", "Russia", "Belarus", "Lithuania", "Latvia", "Estonia"],
  "SouthEastEurope": ["Croatia", "Hungary", "Bosnia", "Serbia", "Montenegro", "Kosovo", "Albania", "North Macedonia", "Greece", "Bulgaria", "Romania"],
  
  //AFRICA
  "NorthAfrica": ["Algeria", "Egypt", "Libya", "Morocco", "Tunisia"],
  "WestAfrica": ["Benin", "Burkina Faso", "Ivory Coast", "Ghana", "Guinea", "Liberia", "Mali", "Mauritania", "Niger", "Nigeria", "Senegal", "Sierra Leone", "Togo"],
  "CentralAfrica": ["Angola", "Cameroon", "Central African Republic", "Chad", "Congo", "Democratic Republic of Congo", "Gabon", "Sudan"],
  "EastAfrica": ["Burundi", "Comoros", "Mauritius", "Djibouti", "Eritrea", "Ethiopia", "Kenya", "Madagascar", "Malawi", "Mozambique", "Rwanda", "Somalia", "South Sudan", "Tanzania", "Uganda", "Zambia"],
  "SouthernAfrica": ["Botswana", "Namibia", "South Africa", "Zimbabwe"],

  //AMERICAS
  "NorthAmerica": ["Canada", "USA", "Mexico"],
  "CentralAmerica": ["Belize", "Guatemala", "Honduras", "El Salvador", "Nicaragua", "Costa Rica", "Panama"],
  "Caribbean": ["Cuba", "Jamaica", "Haiti", "Dominican Republic", "Bahamas", "Barbados", "Trinidad and Tobago"],
  "SouthAmerica": ["Venezuela", "Uruguay", "Paraguay", "Colombia", "Guyana", "Suriname", "Ecuador", "Peru", "Bolivia", "Argentina", "Chile", "Brazil"],
  
  //ASIA
  "NorthEastAsia": ["Korea", "China", "Japan", "NorthKorea", "Mongolia", "Taiwan"],
  "SouthAsia": ["India", "Pakistan", "Bangladesh", "Nepal", "Sri Lanka" ],
  "SouthEastAsia": ["Indonesia", "Malaysia", "Thailand", "Philippines", "Vietnam", "Cambodia", "Myanmar", "Laos", "Singapore"],
  "CentralAsia": ["Kazakhstan", "Kyrgyzstan", "Tajikistan", "Turkmenistan", "Uzbekistan", "Afghanistan"],

  // MIDDLE EAST
  "MiddleEast": ["Saudi Arabia", "United Arab Emirates", "Qatar", "Kuwait", "Bahrain", "Oman", "Yemen", "Iran", "Iraq", "Jordan", "Israel", "Lebanon", "Syria", "Palestine"],
  // OCEANIA
  "Oceania": ["Australia", "New Zealand", "Papua New Guinea", "Fiji", "Solomon Islands"]
};
