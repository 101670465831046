import ChinaFlag from "../assets/flags/flagChina.jpg"
import JapanFlag from "../assets/flags/flagJapan.jpg"
import KoreaFlag from "../assets/flags/flagKorea.jpg"
import NorthKoreaFlag from "../assets/flags/flagNorthKorea.jpg"
import CanadaFlag from "../assets/flags/flagCanada.png"
import USAFlag from "../assets/flags/flagUSA.png"
import VenezuelaFlag from "../assets/flags/flagVenezuela.png"
import ArgentinaFlag from "../assets/flags/flagArgentina.png"
import ChileFlag from "../assets/flags/flagChile.png"
import BrazilFlag from "../assets/flags/flagBrazil.png"
import MexicoFlag from "../assets/flags/flagMexico.png"
import FranceFlag from "../assets/flags/flagFrance.png"
import ItalyFlag from "../assets/flags/flagItaly.png"
import SpainFlag from "../assets/flags/flagSpain.png"
import PortugalFlag from "../assets/flags/flagPortugal.png"
import IndiaFlag from "../assets/flags/flagIndia.png"
import NepalFlag from "../assets/flags/flagNepal.png"
import PakistanFlag from "../assets/flags/flagPakistan.png"
import BangladeshFlag from "../assets/flags/flagBangladesh.png"
import ThailandFlag from "../assets/flags/flagThailand.png"
import CambodiaFlag from "../assets/flags/flagCambodia.png"
import PhilippinesFlag from "../assets/flags/flagPhilippines.png"
import MyanmarFlag from "../assets/flags/flagMyanmar.png"
import MalaysiaFlag from "../assets/flags/flagMalaysia.png"
import IndonesiaFlag from "../assets/flags/flagIndonesia.png"
import VietnamFlag from "../assets/flags/flagVietnam.png"
import UKFlag from "../assets/flags/flagUK.png";
import IrelandFlag from "../assets/flags/flagIreland.png";
import SwedenFlag from "../assets/flags/flagSweden.png";
import DenmarkFlag from "../assets/flags/flagDenmark.png";
import FinlandFlag from "../assets/flags/flagFinland.png";
import NorwayFlag from "../assets/flags/flagNorway.png";
import NetherlandsFlag from "../assets/flags/flagNetherlands.png";
import GermanyFlag from "../assets/flags/flagGermany.png";
import PolandFlag from "../assets/flags/flagPoland.png";
import CzechRepublicFlag from "../assets/flags/flagCzechRepublic.png";
import AustriaFlag from "../assets/flags/flagAustria.png";
import BelgiumFlag from "../assets/flags/flagBelgium.png";
import LuxembourgFlag from "../assets/flags/flagLuxembourg.png";
import SwitzerlandFlag from "../assets/flags/flagSwitzerland.png";
import MoldovaFlag from "../assets/flags/flagMoldova.png";
import UkraineFlag from "../assets/flags/flagUkraine.png";
import RussiaFlag from "../assets/flags/flagRussia.png";
import BelarusFlag from "../assets/flags/flagBelarus.png";
import LithuaniaFlag from "../assets/flags/flagLithuania.png";
import LatviaFlag from "../assets/flags/flagLatvia.png";
import EstoniaFlag from "../assets/flags/flagEstonia.png";
import CroatiaFlag from "../assets/flags/flagCroatia.png";
import HungaryFlag from "../assets/flags/flagHungary.png";
import SlovakiaFlag from "../assets/flags/flagSlovakia.png";
import BosniaFlag from "../assets/flags/flagBosnia.png";
import SerbiaFlag from "../assets/flags/flagSerbia.png";
import MontenegroFlag from "../assets/flags/flagMontenegro.png";
import KosovoFlag from "../assets/flags/flagKosovo.png";
import AlbaniaFlag from "../assets/flags/flagAlbania.png";
import NorthMacedoniaFlag from "../assets/flags/flagNorthMacedonia.png";
import GreeceFlag from "../assets/flags/flagGreece.png";
import BulgariaFlag from "../assets/flags/flagBulgaria.png";
import RomaniaFlag from "../assets/flags/flagRomania.png";
import SloveniaFlag from "../assets/flags/flagSlovenia.png";
import AlgeriaFlag from "../assets/flags/flagAlgeria.png";
import EgyptFlag from "../assets/flags/flagEgypt.png";
import LibyaFlag from "../assets/flags/flagLibya.png";
import MoroccoFlag from "../assets/flags/flagMorocco.png";
import TunisiaFlag from "../assets/flags/flagTunisia.png";
import BeninFlag from "../assets/flags/flagBenin.png";
import BurkinaFasoFlag from "../assets/flags/flagBurkinaFaso.png";
import IvoryCoastFlag from "../assets/flags/flagIvoryCoast.png";
import GhanaFlag from "../assets/flags/flagGhana.png";
import GuineaFlag from "../assets/flags/flagGuinea.png";
import LiberiaFlag from "../assets/flags/flagLiberia.png";
import MaliFlag from "../assets/flags/flagMali.png";
import MauritaniaFlag from "../assets/flags/flagMauritania.png";
import NigerFlag from "../assets/flags/flagNiger.png";
import NigeriaFlag from "../assets/flags/flagNigeria.png";
import SenegalFlag from "../assets/flags/flagSenegal.png";
import SierraLeoneFlag from "../assets/flags/flagSierraLeone.png";
import TogoFlag from "../assets/flags/flagTogo.png";
import AngolaFlag from "../assets/flags/flagAngola.png";
import CameroonFlag from "../assets/flags/flagCameroon.png";
import CARFlag from "../assets/flags/flagCAR.png";
import ChadFlag from "../assets/flags/flagChad.png";
import CongoFlag from "../assets/flags/flagCongo.png";
import DRCFlag from "../assets/flags/flagDRC.png";
import GabonFlag from "../assets/flags/flagGabon.png";
import SudanFlag from "../assets/flags/flagSudan.png";
import BurundiFlag from "../assets/flags/flagBurundi.png";
import ComorosFlag from "../assets/flags/flagComoros.png";
import MauritiusFlag from "../assets/flags/flagMauritius.png";
import DjiboutiFlag from "../assets/flags/flagDjibouti.png";
import EritreaFlag from "../assets/flags/flagEritrea.png";
import EthiopiaFlag from "../assets/flags/flagEthiopia.png";
import KenyaFlag from "../assets/flags/flagKenya.png";
import MadagascarFlag from "../assets/flags/flagMadagascar.png";
import MalawiFlag from "../assets/flags/flagMalawi.png";
import MozambiqueFlag from "../assets/flags/flagMozambique.png";
import RwandaFlag from "../assets/flags/flagRwanda.png";
import SomaliaFlag from "../assets/flags/flagSomalia.png";
import SouthSudanFlag from "../assets/flags/flagSouthSudan.png";
import TanzaniaFlag from "../assets/flags/flagTanzania.png";
import UgandaFlag from "../assets/flags/flagUganda.png";
import ZambiaFlag from "../assets/flags/flagZambia.png";
import BotswanaFlag from "../assets/flags/flagBotswana.png";
import NamibiaFlag from "../assets/flags/flagNamibia.png";
import SouthAfricaFlag from "../assets/flags/flagSouthAfrica.png";
import ZimbabweFlag from "../assets/flags/flagZimbabwe.png";

import BelizeFlag from "../assets/flags/flagBelize.png";
import GuatemalaFlag from "../assets/flags/flagGuatemala.png";
import HondurasFlag from "../assets/flags/flagHonduras.png";
import ElSalvadorFlag from "../assets/flags/flagElSalvador.png";
import NicaraguaFlag from "../assets/flags/flagNicaragua.png";
import CostaRicaFlag from "../assets/flags/flagCostaRica.png";
import PanamaFlag from "../assets/flags/flagPanama.png";
import CubaFlag from "../assets/flags/flagCuba.png";
import JamaicaFlag from "../assets/flags/flagJamaica.png";
import HaitiFlag from "../assets/flags/flagHaiti.png";
import DominicanRepublicFlag from "../assets/flags/flagDominicanRepublic.png";
import BahamasFlag from "../assets/flags/flagBahamas.png";
import BarbadosFlag from "../assets/flags/flagBarbados.png";
import TrinidadAndTobagoFlag from "../assets/flags/flagTrinidadAndTobago.png";
import UruguayFlag from "../assets/flags/flagUruguay.png";
import ParaguayFlag from "../assets/flags/flagParaguay.png";
import ColombiaFlag from "../assets/flags/flagColombia.png";
import GuyanaFlag from "../assets/flags/flagGuyana.png";
import SurinameFlag from "../assets/flags/flagSuriname.png";
import EcuadorFlag from "../assets/flags/flagEcuador.png";
import PeruFlag from "../assets/flags/flagPeru.png";
import BoliviaFlag from "../assets/flags/flagBolivia.png";
import MongoliaFlag from "../assets/flags/flagMongolia.png";
import TaiwanFlag from "../assets/flags/flagTaiwan.png";
import LaosFlag from "../assets/flags/flagLaos.png";
import SingaporeFlag from "../assets/flags/flagSingapore.png";
import KazakhstanFlag from "../assets/flags/flagKazakhstan.png";
import KyrgyzstanFlag from "../assets/flags/flagKyrgyzstan.png";
import TajikistanFlag from "../assets/flags/flagTajikistan.png";
import TurkmenistanFlag from "../assets/flags/flagTurkmenistan.png";
import UzbekistanFlag from "../assets/flags/flagUzbekistan.png";
import AfghanistanFlag from "../assets/flags/flagAfghanistan.png";
import SaudiArabiaFlag from "../assets/flags/flagSaudiArabia.png";
import UnitedArabEmiratesFlag from "../assets/flags/flagUnitedArabEmirates.png";
import QatarFlag from "../assets/flags/flagQatar.png";
import KuwaitFlag from "../assets/flags/flagKuwait.png";
import BahrainFlag from "../assets/flags/flagBahrain.png";
import OmanFlag from "../assets/flags/flagOman.png";
import YemenFlag from "../assets/flags/flagYemen.png";
import IranFlag from "../assets/flags/flagIran.png";
import IraqFlag from "../assets/flags/flagIraq.png";
import JordanFlag from "../assets/flags/flagJordan.png";
import IsraelFlag from "../assets/flags/flagIsrael.png";
import LebanonFlag from "../assets/flags/flagLebanon.png";
import SyriaFlag from "../assets/flags/flagSyria.png";
import PalestineFlag from "../assets/flags/flagPalestine.png";
import AustraliaFlag from "../assets/flags/flagAustralia.png";
import NewZealandFlag from "../assets/flags/flagNewZealand.png";
import PapuaNewGuineaFlag from "../assets/flags/flagPapuaNewGuinea.png";
import FijiFlag from "../assets/flags/flagFiji.png";
import SolomonIslandsFlag from "../assets/flags/flagSolomonIslands.png";
import SriLankaFlag from "../assets/flags/flagSriLanka.png";

import AsiaIcon from "../assets/flags/iconAsia.png";
import AfricaIcon from "../assets/flags/iconAfrica.png";
import EuropeIcon from "../assets/flags/iconEurope.png";
import GlobeIcon from "../assets/flags/iconGlobe.png";
import AmericasIcon from '../assets/flags/iconAmericas.png'

export const flagMap: { [key: string]: string } = {
  "China": ChinaFlag,
  "Japan": JapanFlag,
  "Korea": KoreaFlag,
  "NorthKorea": NorthKoreaFlag,
  "Canada": CanadaFlag,
  "USA": USAFlag,
  "Venezuela": VenezuelaFlag,
  "Argentina": ArgentinaFlag,
  "Chile": ChileFlag,
  "Brazil": BrazilFlag,
  "Mexico": MexicoFlag,
  "France": FranceFlag,
  "Italy": ItalyFlag,
  "Spain": SpainFlag,
  "Portugal": PortugalFlag,
  "India": IndiaFlag,
  "Nepal": NepalFlag,
  "Pakistan": PakistanFlag,
  "Bangladesh": BangladeshFlag,
  "Thailand": ThailandFlag,
  "Cambodia": CambodiaFlag,
  "Philippines": PhilippinesFlag,
  "Myanmar": MyanmarFlag,
  "Malaysia": MalaysiaFlag,
  "Indonesia": IndonesiaFlag,
  "Vietnam": VietnamFlag,
  "UK": UKFlag,
  "Ireland": IrelandFlag,
  "Sweden": SwedenFlag,
  "Denmark": DenmarkFlag,
  "Finland": FinlandFlag,
  "Norway": NorwayFlag,
  "Netherlands": NetherlandsFlag,
  "Germany": GermanyFlag,
  "Poland": PolandFlag,
  "Czech Republic": CzechRepublicFlag,
  "Austria": AustriaFlag,
  "Belgium": BelgiumFlag,
  "Luxembourg": LuxembourgFlag,
  "Switzerland": SwitzerlandFlag,
  "Moldova": MoldovaFlag,
  "Ukraine": UkraineFlag,
  "Russia": RussiaFlag,
  "Belarus": BelarusFlag,
  "Lithuania": LithuaniaFlag,
  "Latvia": LatviaFlag,
  "Estonia": EstoniaFlag,
  "Croatia": CroatiaFlag,
  "Hungary": HungaryFlag,
  "Slovakia": SlovakiaFlag,
  "Bosnia": BosniaFlag,
  "Serbia": SerbiaFlag,
  "Montenegro": MontenegroFlag,
  "Kosovo": KosovoFlag,
  "Albania": AlbaniaFlag,
  "North Macedonia": NorthMacedoniaFlag,
  "Greece": GreeceFlag,
  "Bulgaria": BulgariaFlag,
  "Romania": RomaniaFlag,
  "Slovenia": SloveniaFlag,
  "Algeria": AlgeriaFlag,
  "Egypt": EgyptFlag,
  "Libya": LibyaFlag,
  "Morocco": MoroccoFlag,
  "Tunisia": TunisiaFlag,
  "Benin": BeninFlag,
  "Burkina Faso": BurkinaFasoFlag,
  "Ivory Coast": IvoryCoastFlag,
  "Ghana": GhanaFlag,
  "Guinea": GuineaFlag,
  "Liberia": LiberiaFlag,
  "Mali": MaliFlag,
  "Mauritania": MauritaniaFlag,
  "Niger": NigerFlag,
  "Nigeria": NigeriaFlag,
  "Senegal": SenegalFlag,
  "Sierra Leone": SierraLeoneFlag,
  "Togo": TogoFlag,
  "Angola": AngolaFlag,
  "Cameroon": CameroonFlag,
  "Central African Republic": CARFlag,
  "Chad": ChadFlag,
  "Congo": CongoFlag,
  "Democratic Republic of Congo": DRCFlag,
  "Gabon": GabonFlag,
  "Sudan": SudanFlag,
  "Burundi": BurundiFlag,
  "Comoros": ComorosFlag,
  "Mauritius": MauritiusFlag,
  "Djibouti": DjiboutiFlag,
  "Eritrea": EritreaFlag,
  "Ethiopia": EthiopiaFlag,
  "Kenya": KenyaFlag,
  "Madagascar": MadagascarFlag,
  "Malawi": MalawiFlag,
  "Mozambique": MozambiqueFlag,
  "Rwanda": RwandaFlag,
  "Somalia": SomaliaFlag,
  "South Sudan": SouthSudanFlag,
  "Tanzania": TanzaniaFlag,
  "Uganda": UgandaFlag,
  "Zambia": ZambiaFlag,
  "Botswana": BotswanaFlag,
  "Namibia": NamibiaFlag,
  "South Africa": SouthAfricaFlag,
  "Zimbabwe": ZimbabweFlag,

  "Belize": BelizeFlag,
  "Guatemala": GuatemalaFlag,
  "Honduras": HondurasFlag,
  "El Salvador": ElSalvadorFlag,
  "Nicaragua": NicaraguaFlag,
  "Costa Rica": CostaRicaFlag,
  "Panama": PanamaFlag,
  "Cuba": CubaFlag,
  "Jamaica": JamaicaFlag,
  "Haiti": HaitiFlag,
  "Dominican Republic": DominicanRepublicFlag,
  "Bahamas": BahamasFlag,
  "Barbados": BarbadosFlag,
  "Trinidad and Tobago": TrinidadAndTobagoFlag,
  "Uruguay": UruguayFlag,
  "Paraguay": ParaguayFlag,
  "Colombia": ColombiaFlag,
  "Guyana": GuyanaFlag,
  "Suriname": SurinameFlag,
  "Ecuador": EcuadorFlag,
  "Peru": PeruFlag,
  "Bolivia": BoliviaFlag,
  "Mongolia": MongoliaFlag,
  "Taiwan": TaiwanFlag,
  "Laos": LaosFlag,
  "Singapore": SingaporeFlag,
  "Kazakhstan": KazakhstanFlag,
  "Kyrgyzstan": KyrgyzstanFlag,
  "Tajikistan": TajikistanFlag,
  "Turkmenistan": TurkmenistanFlag,
  "Uzbekistan": UzbekistanFlag,
  "Afghanistan": AfghanistanFlag,
  "Saudi Arabia": SaudiArabiaFlag,
  "United Arab Emirates": UnitedArabEmiratesFlag,
  "Qatar": QatarFlag,
  "Kuwait": KuwaitFlag,
  "Bahrain": BahrainFlag,
  "Oman": OmanFlag,
  "Yemen": YemenFlag,
  "Iran": IranFlag,
  "Iraq": IraqFlag,
  "Jordan": JordanFlag,
  "Israel": IsraelFlag,
  "Lebanon": LebanonFlag,
  "Syria": SyriaFlag,
  "Palestine": PalestineFlag,
  "Australia": AustraliaFlag,
  "New Zealand": NewZealandFlag,
  "Papua New Guinea": PapuaNewGuineaFlag,
  "Fiji": FijiFlag,
  "Solomon Islands": SolomonIslandsFlag,
  "Sri Lanka": SriLankaFlag,

  "Africa": AfricaIcon,
  "Americas": AmericasIcon,
  "Asia": AsiaIcon,
  "Europe": EuropeIcon,
  "Globe": GlobeIcon,
};

export const countryDisplayMap: { [key: string]: string } = {
  "North Macedonia": "N. Macedonia",
  "Czech Republic": "C. Republic",
  "NorthKorea": "N. Korea",
  "South Africa": "S. Africa",
  "Central African Republic": "C.A.R.",
  "Democratic Republic of Congo": "DR Congo",
  "South Sudan": "S. Sudan",
  "Sierra Leone": "S. Leone",
  "Burkina Faso": "Burkina F.",
  "Dominican Republic": "D. Republic",
  "Trinidad and Tobago": "Trinidad & Tobago",
  "United Arab Emirates": "UAE",
};
