import React, { useEffect, createContext, useContext, useState, ReactNode } from 'react';

import { GameStateType } from '../types/GameStateType';
import { GameModeType } from '../types/GameModeType';
import { GameDifficultyType } from '../types/GameDifficultyType';

import { getInitialGameMode } from '../helpers/gameHelpers';

interface GameContextType {
  gameState: GameStateType;
  setGameState: (state: GameStateType) => void;
  gameMode: GameModeType;
  setGameMode: (state: GameModeType) => void;
  gameDifficulty: GameDifficultyType; 
  setGameDifficulty: (state: GameDifficultyType) => void,
}

const defaultContextValue: GameContextType = {
  gameState: GameStateType.Instructions,
  setGameState: () => {},
  gameMode: GameModeType.Globe,
  setGameMode: () => {},
  gameDifficulty: GameDifficultyType.Medium,
  setGameDifficulty: () => {},
};

const GameContext = createContext<GameContextType>(defaultContextValue);

interface GameProviderProps {
  children: ReactNode;
}

export const GameProvider: React.FC<GameProviderProps> = ({ children }) => {
  const [gameState, setGameState] = useState<GameStateType>(GameStateType.Instructions);
  const [gameMode, setGameMode] = useState<GameModeType>(getInitialGameMode);
  const [gameDifficulty, setGameDifficulty] = useState<GameDifficultyType>(GameDifficultyType.Easy);

  useEffect(() => {
    try {
      localStorage.setItem('gameMode', gameMode);
    } catch (error) {
      console.error('Error saving gameMode to localStorage:', error);
    }
  }, [gameMode, setGameMode]);

  return (
    <GameContext.Provider value={{ 
        gameState, 
        setGameState, 
        gameMode, 
        setGameMode,
        gameDifficulty, 
        setGameDifficulty
      }}>
      {children}
    </GameContext.Provider>
  );
};

export const useGame = () => useContext(GameContext);
