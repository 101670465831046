import React, { useState } from 'react'

import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import './GameImages.css'
import ImageModal from './ImageModal/ImageModal';

interface GameImagesProps {
  imageUrl: string,
}

const GameImages: React.FC<GameImagesProps> = ({ imageUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <div className='GameImages'>
      <img src={imageUrl} alt="Person" />

      <button className="fullscreen-button" onClick={() => setIsModalOpen(true)}>
        <OpenInFullIcon sx={{ fontSize: 20 }} />
      </button>

      {isModalOpen && (
        <ImageModal imageUrl={imageUrl} setIsModalOpen={setIsModalOpen} />
      )}
    </div>
  )
}

export default GameImages