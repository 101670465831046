
export const formatDate = (date: Date): string => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const isSameDay = (d1: Date, d2: Date): boolean => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  if (isSameDay(date, today)) {
    // If the date is today, display only time
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  } else if (isSameDay(date, yesterday)) {
    // If the date is yesterday, display 'Yesterday'
    return 'Yesterday';
  } else {
    // Otherwise, display only the date
    return date.toLocaleDateString();
  }
};
