export const getBrowser = () => {
  const userAgent = navigator.userAgent;

  if (/Edg\/\d+/.test(userAgent)) {
    return 'Edge';
  } else if (/OPR\/\d+/.test(userAgent)) {
    return 'Opera';
  } else if (/Chrome\/\d+/.test(userAgent)) {
    return 'Chrome';
  } else if (/Firefox\/\d+/.test(userAgent)) {
    return 'Firefox';
  } else if (/Safari\/\d+/.test(userAgent)) {
    return 'Safari';
  } else {
    return 'Unknown';
  }
};