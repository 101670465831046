export const levelColourData: Record<string, string> = {
  '1': "#595959",
  '2': "#C4978B",
  '3': "#BAC5DF",
  '4': "#F8D267",
  '5': "#41C789",
  '6': "#477FF9",
  '7': "#9C88FF",
  '8': "#eaac22",
  '9': "#FF8888",
  '10': "linear-gradient(to right, #572AE8, #F251CB, #FF9900)",
};