import React from 'react'

import "./SpecialButton.css"

interface SpecialButtonProps {
  text: string;
  onClick: () => void;
}

const SpecialButton: React.FC<SpecialButtonProps> = ({ text, onClick }) => {
  return (
    <button className='SpecialButton' onClick={onClick}>
      {text}
    </button>
  )
}

export default SpecialButton