import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

// Your web app's Firebase configuration
const firebaseConfig: FirebaseConfig = {
  apiKey: "AIzaSyDgWHOeiHFzwTFS3KY_LR2oRZrU0apicxM",
  authDomain: "areyouracist-ayr.firebaseapp.com",
  projectId: "areyouracist-ayr",
  storageBucket: "areyouracist-ayr.appspot.com",
  messagingSenderId: "426263780767",
  appId: "1:426263780767:web:c2c939ad4f4592923c31aa",
  measurementId: "G-VQRQ0YCCXY"
};

// Export initialized services
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth, analytics, app };
