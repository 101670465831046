import level1 from '../assets/levels/level1.png';
import level2 from '../assets/levels/level2.png';
import level3 from '../assets/levels/level3.png';
import level4 from '../assets/levels/level4.png';
import level5 from '../assets/levels/level5.png';
import level6 from '../assets/levels/level6.png';
import level7 from '../assets/levels/level7.png';
import level8 from '../assets/levels/level8.png';
import level9 from '../assets/levels/level9.png';
import level10 from '../assets/levels/level10.png';

export const levelImageMap: Record<string, string> = {
  '1': level1,
  '2': level2,
  '3': level3,
  '4': level4,
  '5': level5,
  '6': level6,
  '7': level7,
  '8': level8,
  '9': level9,
  '10': level10,
};
