import React, { useState, useEffect } from 'react'

import './Friends.css'

import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { useAuth } from '../../../Context/AuthContext';
import { useAlert } from '../../../Context/AlertContext';
import { useGame } from '../../../Context/GameContext';
import { usePoints } from '../../../Context/PointsContext';

import { sendFriendRequest, fetchUserIdByUsername, getFriends } from '../../../services/firestoreService';

import { FriendType } from '../../../types/FriendType';

import Friend from './Friend/Friend';

interface FriendsProps {
  setOpenLoginModal: (open: boolean) => void;
}

const Friends: React.FC<FriendsProps> = ({ setOpenLoginModal }) => {
  const { showAlert } = useAlert()
  const { user } = useAuth()
  const { gameMode, gameState } = useGame()
  const { currentPoints, highscoreStreak } = usePoints()

  const [friendUsername, setFriendUsername] = useState<string>('');
  const [friendsList, setFriendsList] = useState<FriendType[]>([]);

  const currentUserId = user?.uid;
  const currentUsername = user?.username;

  const handleFriendRequest = async () => {
    if (!currentUserId || !currentUsername) {
      showAlert('You must be logged in to send friend requests.', "red");
      return;
    }

    if (friendUsername.trim() === '') {
      showAlert('Please enter a username.', "red");
      return;
    }

    try {
      const targetUserId = await fetchUserIdByUsername(friendUsername);

      if (targetUserId) {
        await sendFriendRequest(currentUserId || '', targetUserId, currentUsername, showAlert);
        setFriendUsername('');
      } else {
        showAlert("No user found with that name", "red")
      }
    } catch (error) {
      console.error('Error sending friend request:', error);
      setFriendUsername('Error sending friend request, try again later');
    }
  }

  useEffect(() => {
    const fetchFriends = async () => {
      if (!currentUserId) return;

      try {
        const friends = await getFriends(currentUserId, gameMode);
        setFriendsList(friends);
      } catch (error) {
        console.error('Error fetching friends:', error);
      }
    };

    fetchFriends();
  }, [currentUserId, gameMode, gameState]);

  return (
    <div className='Friends'>
      <div className="friends-title">
        <PeopleIcon sx={{ fontSize: 20 }} />
        <h2>Friends</h2>
      </div>

      {user?.userType === "authenticated" ? (
        <>
          <div className="friends-container">
            {
              friendsList.length < 1 ? (
                <p className='no-friends'>Your friends will appear here.</p>
              ): (
                <>
                  {user && (
                    <Friend
                      key={user?.uid}
                      friend={{
                        id: user?.uid || '',
                        username: user?.username || 'Unknown',
                        currentPoints: currentPoints,
                        highscoreStreak: highscoreStreak,
                      }}
                      isUser={true}
                    />        
                  )}
                  {friendsList.map((friend, index) => (
                    <Friend key={friend.id} friend={friend} isUser={false} rank={index + 1} />
                  ))}
                </>
              )
            }
          </div>
          
          <div className="friend-request">
              <input
                type="text"
                value={friendUsername}
                onChange={(e) => setFriendUsername(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                placeholder="Add friend by username..."
              />
              <button 
                disabled={friendUsername.length > 0 ? false : true}
                onClick={handleFriendRequest}
              >
                <PersonAddIcon sx={{ fontSize: 20 }} />
              </button>
            </div>
          </>
      ): (
        <button 
          className='login-for-friends'
          onClick={() => setOpenLoginModal(true)}
        >
          Log in to view & add friends
        </button>
      )}
    </div>
  )
}

export default Friends