import React, { useState, useEffect } from 'react'

import './Navbar.css';

import DiscordIcon from '../../assets/discordLogo.png'

import BasicButton from '../BasicButton/BasicButton';
import ProgressBar from '../ProgressBar/ProgressBar';
import AYRLogo from '../AYRLogo/AYRLogo';

import ProfileElement from './ProfileElement/ProfileElement';

import { GameStateType } from '../../types/GameStateType';

import { useGame } from '../../Context/GameContext';

interface NavbarProps {
  setOpenLoginModal: (open: boolean) => void;
  setOpenProfileModal: (open: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = ({ setOpenLoginModal, setOpenProfileModal }) => {
  const { gameState } = useGame()
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 950);
  const [removeLoginText, setRemoveLoginText] = useState(window.innerWidth < 390);
  const [discordText, setDiscordText] = useState(window.innerWidth < 390 ? "" : "Discord");

  const handleOpenDiscord = () => {
    window.open("https://discord.gg/XmMBS32yB4", '_blank')
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 950);
      if (window.innerWidth < 390) {
        setRemoveLoginText(true)
        setDiscordText("")
      } else if (window.innerWidth < 450) {
        setDiscordText("")
      } else if (window.innerWidth < 630) {
        setDiscordText("Discord")
      } else if (window.innerWidth < 800) {
        setDiscordText("Discord")
      } 
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (gameState === GameStateType.Playing) {
      setIsPlaying(true)
    } else {
      setIsPlaying(false)
    }
  }, [gameState])

  return (
    <nav className="Navbar">
      {isMobile ? (
        <div className="mobile-navbar">
          <div className="mobile-top">
            <div className="left-nav-container">
              <BasicButton text={discordText} icon={
                <img src={DiscordIcon} className='discord-icon' alt='discord'/>
              } onClick={handleOpenDiscord}/>
            </div>

            <ProfileElement iconSize={20} setOpenLoginModal={setOpenLoginModal} setOpenProfileModal={setOpenProfileModal} removeLoginText={removeLoginText} />
            </div>

          <div className='nav-middle'>
            <AYRLogo />
            <ProgressBar />
          </div>
        </div>
      ) : (
        <div style={{ alignItems: isPlaying ? 'center' : 'start'}} className="desktop-navbar">
          <div className="left-nav-container">
            <BasicButton text='Join our Discord' icon={
              <img src={DiscordIcon} className='discord-icon' alt='discord'/>
            } onClick={handleOpenDiscord}/>
          </div>

          <div className='nav-middle'>
            {isPlaying ? (
              <ProgressBar />
            ) : (
              <AYRLogo />
            )}
          </div>

          <ProfileElement iconSize={20} setOpenLoginModal={setOpenLoginModal} setOpenProfileModal={setOpenProfileModal} removeLoginText={removeLoginText} />
        </div>
      )}

    </nav>
  )
}

export default Navbar;