import React from 'react'

import './Friend.css'

import { FriendType } from '../../../../types/FriendType'

import PointsStreak from '../../Game/Statistics/PointsStreak/PointsStreak'

interface FriendProps{
  friend: FriendType,
  isUser: boolean,
  rank?: number,
}

const levels = [0, 50, 150, 300, 500, 1000, 1750, 2500, 3500, 5000];
const levelNames = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const Friend: React.FC<FriendProps> = ({ friend, isUser, rank }) => {

  const getLevelName = (points: number) => {
    for (let i = levels.length - 1; i >= 0; i--) {
      if (points >= levels[i]) {
        return(levelNames[i]);
      }
    }
  }

  const currentLevelName: string | undefined = getLevelName(friend.currentPoints)

  return (
    <div className={`Friend ${isUser ? 'is-user' : ''}`} key={friend.id}>

      <div className="rank-user-container">
        {rank && (
          <span className='rank'>{rank}</span>
        )}

        <p className='friend-username'>{isUser ? "You" : friend.username}</p>
      </div>
      
      <div className="friend-streak-points-container">
        <PointsStreak highscoreStreak={friend.highscoreStreak} imageType={isUser ? "streakWhiteBorder" : "streakNo"} />
        <PointsStreak highscoreStreak={friend.currentPoints} currentLevelName={currentLevelName} />
      </div>
    </div>
  )
}

export default Friend