import React from 'react'

import "./AYRLogo.css"

import { useGame } from '../../Context/GameContext'
import { GameStateType } from '../../types/GameStateType'

const AYRLogo: React.FC = () => {
  const { gameState } = useGame();

  if (gameState === GameStateType.Playing) {
    return null;
  }

  return (
    <>
      <h1>Nationality Guesser</h1>
    </>
  )
}

export default AYRLogo;