import React from 'react'

import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import './ImageModal.css'

interface ImageModalProps {
  imageUrl: string;
  setIsModalOpen: (value: boolean) => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ imageUrl, setIsModalOpen }) => {

  return (
    <div className="ImageModal" onClick={() => setIsModalOpen(false)}>
      <div className="image-container">
        <img src={imageUrl} alt="Full screen" onClick={(e) => e.stopPropagation()}></img>
        <button className="CloseButton" onClick={() => setIsModalOpen(false)}>
          <CloseFullscreenIcon sx={{ fontSize: 22 }} />
          Close
        </button>
      </div>
    </div>
  )
}

export default ImageModal