import React, { useEffect, useState, useRef } from 'react'
import './ProgressBar.css'

import { usePoints } from '../../Context/PointsContext';
import { useGame } from '../../Context/GameContext';

import { GameStateType } from '../../types/GameStateType';

import successParticlesWEBM from '../../assets/animations/successParticlesWEBM.webm'
import successParticlesMOV from '../../assets/animations/successParticlesMOV.mov'

import PointsStreak from '../Body/Game/Statistics/PointsStreak/PointsStreak';

import { getBrowser } from '../../helpers/browserHelper';

import { levelColourData } from '../../config/levelColourData';

interface progressBarProps {
  type?: string,
}

const ProgressBar: React.FC<progressBarProps> = ({ type = "regular" }) => {
  const { currentPoints, previousLevelPoints, nextLevelPoints, lastAnswer, currentLevelName } = usePoints();

  const { gameState } = useGame();
  const [isSupportedBrowser, setIsSupportedBrowser] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const browser = getBrowser()

  const isRegular = (type === "regular");

  let progressWidth = ((currentPoints - previousLevelPoints) / (nextLevelPoints - previousLevelPoints)) * 100;
  if (currentPoints > 5000 || currentPoints < 0) {
    progressWidth = 100;
  }

  useEffect(() => {
    if (lastAnswer && videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch(e => console.error("Error playing video:", e));
    }
  }, [currentPoints, lastAnswer]);

  useEffect(() => {
    if (browser !== "Chrome" && browser !== "Firefox") {
      setIsSupportedBrowser(false)
    }
  }, [browser])

  useEffect(() => {
    if (gameState === GameStateType.Playing) {
      setIsPlaying(true)
    } else {
      setIsPlaying(false)
    }
  }, [gameState])

  return (
    <div className='progress-bar' 
      style={{ width: (isPlaying || !isRegular) ? '100%' : '400px', backgroundColor: isRegular ? "#37393c" : "#f3f3f3" }} 
    >
      {isRegular && (
        <div className='progress-text-bg'>
          <PointsStreak highscoreStreak={currentPoints} imageType={"pointsWhiteBorder"} currentLevelName={currentLevelName} />
        </div>
      )}
      <div className="progress-fillup" style={{ width: `${progressWidth}%`, background: levelColourData[currentLevelName] }}>
        {isRegular && (
          <div className='progress-fillup-light'></div>
        )}
        {isSupportedBrowser && isRegular && (
          <video 
            ref={videoRef}
            muted 
            playsInline
            src={isSupportedBrowser ? successParticlesWEBM : successParticlesMOV}
            className='video'
            style={{ display: lastAnswer === true ? 'block' : 'none' }}
          />
        )}
      </div>


    </div>
  )
}

export default ProgressBar

