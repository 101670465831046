import React from 'react';
import './Footer.css';

import { GameStateType } from '../../types/GameStateType';
import { useGame } from '../../Context/GameContext';

const Footer: React.FC = () => {
  const { gameState } = useGame();

  if (gameState === GameStateType.Playing) {
    return null;
  }

  return (
    <div className='Footer'>
      <a
        href="https://docs.google.com/document/d/1aHn96cZau-a1hOPhtiuFNmlyiXjL7WllKmJZ1oWcPmE/pub"
        target='_blank'
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      <p>|</p>
      <a
        href="https://docs.google.com/document/d/1aHn96cZau-a1hOPhtiuFNmlyiXjL7WllKmJZ1oWcPmE/pub"
        target='_blank'
        rel="noreferrer"
      >
        Terms of Service
      </a>
    </div>
  );
};

export default Footer;
