import React, { createContext, useContext, useState, ReactNode } from 'react';

import AlertElement from '../components/AlertElement/AlertElement';

interface AlertContextProps {
  showAlert: (message: string, status: 'red' | 'green' | 'blue' | null) => void;
  hideAlert: () => void;
}

const AlertContext = createContext<AlertContextProps>({
  showAlert: () => {},
  hideAlert: () => {},
});

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<'red' | 'green' | 'blue' | null>(null);

  const showAlert = (message: string, status: 'red' | 'green' | 'blue' | null) => {
    setAlertMessage(message);
    setAlertStatus(status);
    setTimeout(hideAlert, 3000);
  };

  const hideAlert = () => {
    setAlertMessage(null);
    setAlertStatus(null);
  };

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      {children}
      {alertMessage && <AlertElement message={alertMessage} status={alertStatus} />}
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
