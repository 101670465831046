import React from 'react'

import './Choice.css'

import { flagMap, countryDisplayMap } from '../../../../../../config/flagData'

interface ChoiceProps {
  country: string,
  checkAnswer: () => void;
  isCorrect: boolean;
  showCorrect: boolean;
}

const Choice: React.FC<ChoiceProps> = ({ country, checkAnswer, isCorrect, showCorrect }) => {
  const flagImage = flagMap[country];
  const displayCountry = countryDisplayMap[country] || country;
  
  const className = `Choice ${showCorrect && isCorrect ? 'correct' : ''}`;

  return (
    <button className={className} onClick={checkAnswer}>
      <img src={flagImage} alt={`${country} flag`} />
      <p>{displayCountry}</p> 
    </button>
  );
}

export default Choice;