import React, { useEffect, useState } from 'react'

import './GameControlBar.css'

import PointsStreak from '../../Statistics/PointsStreak/PointsStreak';

import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

import ControlButton from './ControlButton/ControlButton';
import MessageSubmitModal from '../../../../MessageSubmitModal/MessageSubmitModal';

import { usePoints } from '../../../../../Context/PointsContext';

import { Quiz } from '../../../../../types/Quiz';

import { increaseWrongGuess } from '../../../../../services/firestoreService';

import Skip from '../../../../../assets/icons/Skip-white-bg.png'

interface GameControlProps {
  nextQuiz: () => void,
  currentQuiz: Quiz;
}

const GameControlBar: React.FC<GameControlProps> = ({ nextQuiz, currentQuiz }) => {
  const { skipCount, currentPoints, gameStreak, handleSkipCountUsed, currentLevelName } = usePoints()

  const [skipsExhausted, setSkipsExhausted] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (skipCount < 1) {
      setSkipsExhausted(true)
    } else {
      setSkipsExhausted(false)
    }
  }, [skipCount])

  const handleToggleModal = () => {
    setOpenModal(current => !current)
  }

  const handleSkipClick = () => {
    if (skipCount >= 1) {
      nextQuiz()
      handleSkipCountUsed()
      increaseWrongGuess(currentQuiz.id)
    }
  }

  return (
    <div className='GameControlBar'>
      <div className="modalContainer">
        <ControlButton 
          text={"Report"} 
          icon={<ReportGmailerrorredIcon sx={{ fontSize: 20 }} />}
          onClick={handleToggleModal}
          className={'controlButton'}
        />
        {openModal && <MessageSubmitModal handleToggleModal={handleToggleModal} currentQuiz={currentQuiz} />}
      </div>

      <PointsStreak highscoreStreak={gameStreak} imageType={"streakWhiteBorder"} maxxed={true} />
      <PointsStreak highscoreStreak={currentPoints} imageType={"pointsWhiteBorder"} maxxed={true} currentLevelName={currentLevelName} />
      
      <ControlButton 
        text={skipCount > 1 ? `${skipCount} Skips` : "Skip"}
        icon={<img src={Skip} alt="skip" className='skip-icon' />}
        onClick={handleSkipClick}
        className={skipsExhausted ? 'controlButtonLocked' : 'controlButton'}
      />
    </div>
  )
}

export default GameControlBar