import React from 'react'
import './ProgressBarMini.css'

import { levelColourData } from '../../config/levelColourData';

interface ProgressBarMiniProps {
  points: number,
  levelInfo: {
    currentLevelName: string;
    previousLevelPoints: number;
    nextLevelPoints: number;
  };
}

const ProgressBarMini: React.FC<ProgressBarMiniProps> = ({ points, levelInfo}) => {

  let progressWidth = ((points - levelInfo.previousLevelPoints) / (levelInfo.nextLevelPoints - levelInfo.previousLevelPoints)) * 100;
  if (points > 5000) {
    progressWidth = 100;
  }
  if (points < 0) {
    progressWidth = 0;
  }

  return (
    <div className='progress-bar-mini'>
      <div 
        className="progress-fillup" 
        style={{ width: `${progressWidth}%`, background: levelColourData[levelInfo.currentLevelName] }}
      >
      </div>
    </div>
  )
}

export default ProgressBarMini

