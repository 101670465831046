import React from 'react'

import "./Instructions.css"

import { GameStateType } from '../../../../types/GameStateType';

import SpecialButton from '../../../SpecialButton/SpecialButton'

interface InstructionsProps {
  setGameState: (state: GameStateType) => void;
}

const Instructions: React.FC<InstructionsProps> = ({ setGameState}) => {

  return (
    <div className='Instructions'>
      <h1><span>Nationality Guesser</span> is just a little game where you learn to distinguish nationalities. It's meant for entertainment and education, not offence.</h1>
      <SpecialButton text='I understand, Start' onClick={() => setGameState(GameStateType.Playing)} />
    </div>
  )
}

export default Instructions