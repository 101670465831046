import React, { useState, useCallback } from 'react';
import './App.css';

import Navbar from './components/Navbar/Navbar';
import Body from './components/Body/Body';
import Footer from './components/Footer/Footer';

import LoginModal from './components/LoginModal/LoginModal';
import ProfileModal from './components/ProfileModal/ProfileModal';

import { PointsProvider } from './Context/PointsContext';
import { GameProvider } from './Context/GameContext';
import { AuthProvider } from './Context/AuthContext';
import { AnalyticsProvider } from './Context/AnalyticsContext';
import { AlertProvider } from './Context/AlertContext';

const App: React.FC = () => {
  const [showLoginmodal, setShowLoginModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const setLoginModal = useCallback((state: boolean) => {
    setShowLoginModal(state);
  }, []);

  const setProfileModal = useCallback((state: boolean) => {
    setShowProfileModal(state);
  }, []);

  return (
    <AnalyticsProvider>
      <AlertProvider> 
        <GameProvider>
          <AuthProvider>
            <PointsProvider>
              <div className="App">
                <Navbar setOpenLoginModal={setLoginModal} setOpenProfileModal={setProfileModal} />
                <Body setOpenLoginModal={setLoginModal} />
                <Footer />
                {showLoginmodal && (
                  <LoginModal setOpenLoginModal={setLoginModal} />
                )}
                {showProfileModal && (
                  <ProfileModal setOpenProfileModal={setProfileModal} />
                )}
              </div>
            </PointsProvider>
          </AuthProvider> 
        </GameProvider>
      </AlertProvider>
    </AnalyticsProvider>
  );
}

export default App;