import React, { createContext, useContext, ReactNode } from 'react';

import { usePointsSystem } from '../Hooks/usePointsSystem';

import { useAuth } from './AuthContext';

import { useGame } from './GameContext';

interface PointsSystemContext {
  currentPoints: number;
  previousLevelPoints: number;
  nextLevelPoints: number;
  currentLevelName: string;
  gameStreak: number;
  highscoreStreak: number;
  lastAnswer: boolean | null;
  skipCount: number;
  handleSkipCountUsed: () => void;
  handleCorrectAnswer: (quizId: string, difficulty: string) => void;
  handleIncorrectAnswer: (quizId: string, difficulty: string) => void;
  handleChangeInGamemodeDifficulty: () => void;
}

const defaultContext: PointsSystemContext = {
  currentPoints: 0,
  previousLevelPoints: 0,
  nextLevelPoints: 50,
  currentLevelName: '1',
  gameStreak: 0,
  highscoreStreak: 0,
  lastAnswer: null,
  skipCount: 1,
  handleSkipCountUsed: () => {},
  handleCorrectAnswer: (quizId: string, difficulty: string) => {},
  handleIncorrectAnswer: (quizId: string, difficulty: string) => {},
  handleChangeInGamemodeDifficulty: () => {}
};

const PointsContext = createContext<PointsSystemContext>(defaultContext);

interface PointsProviderProps {
  children: ReactNode;
}

export const PointsProvider: React.FC<PointsProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const { gameMode, setGameDifficulty } = useGame()

  const userAllowedSkips = user?.subscription === "Premium" ? 5 : 1

  const pointsHook = usePointsSystem(user?.uid || "", gameMode , userAllowedSkips, setGameDifficulty);

  return (
    <PointsContext.Provider value={pointsHook}>
      {children}
    </PointsContext.Provider>
  );
};

export const usePoints = () => useContext(PointsContext);
