import React from 'react'

import './NotificationItem.css'

import { respondToFriendRequest } from '../../../../services/firestoreService'

import { formatDate } from '../../../../helpers/dateHelpers';

import { Notification } from '../../../../types/NotificationType';

interface NotificationitemProps{
  notification: Notification | any,
  userId: string,
}

const NotificationItem: React.FC<NotificationitemProps> = ({ notification, userId }) => {
  const formattedDate = formatDate(notification.timestamp.toDate());
  let notificationTitle: string;
  let notificationBody: string;

  if (notification.type === "friend_request") {
    notificationTitle = "Friend Request"
    notificationBody = `You've received a friend request from ${notification.fromUsername}.`
  } else {
    notificationBody = notification.text;
    notificationTitle = notification.title;
  }

  const handleAcceptFriendReq = async () => {
    try {
      await respondToFriendRequest(userId, notification.id, notification.friendshipId, 'accept');
      console.log('Friend request accepted');
    } catch (error) {
      console.error('Error accepting friend request:', error);
    }
  };

  const handleIgnoreFriendReq = async () => {
    try {
      await respondToFriendRequest(userId, notification.id, notification.friendshipId, 'ignore');
      console.log('Friend request declined');
    } catch (error) {
      console.error('Error declining friend request:', error);
    }
  };

  return (
    <div className="NotificationItem">
      <div className="notification-top">
        <h3>{notificationTitle}</h3>
        <p className='date'>{formattedDate}</p>
      </div>
      
      <p className='notification-body'>{notificationBody}</p>

      {notification.type === 'friend_request' && (
        <div className="buttons-container">
          <button className='accept' onClick={handleAcceptFriendReq}>Accept</button>
          <button className='ignore' onClick={handleIgnoreFriendReq}>Ignore</button>
        </div>
      )}
    </div>
  )
}

export default NotificationItem