import React from 'react'

import "./LeaderboardMember.css"

import { LeaderboardMemberType } from '../../../../types/LeaderboardMemberType'

import { useAuth } from '../../../../Context/AuthContext'

import PointsStreak from '../../Game/Statistics/PointsStreak/PointsStreak'

interface LeaderboardMemberProps {
  member: LeaderboardMemberType,
  rank: number,
}

const levels = [0, 50, 150, 300, 500, 1000, 1750, 2500, 3500, 5000];
const levelNames = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const LeaderboardMember: React.FC<LeaderboardMemberProps> = ({ member, rank }) => {
  const { user } = useAuth();

  const memberIsHim = member.id === user?.uid

  let rankClass = 'default';

  if (rank === 1) {
    rankClass = 'first';
  } else if (rank === 2) {
    rankClass = 'second';
  } else if (rank === 3) {
    rankClass = 'third';
  }
  
  const getLevelName = (points: number) => {
    for (let i = levels.length - 1; i >= 0; i--) {
      if (points >= levels[i]) {
        return(levelNames[i]);
      }
    }
  }

  const currentLevelName: string | undefined = getLevelName(member.currentPoints)

  return (
    <div className={`LeaderboardMember ${rankClass} ${memberIsHim ? "isUser" : ""}`}>

      <div className="rank-user-container">
        <span className='rank'>{rank}</span>

        <p className="member">
          {memberIsHim ? "You" : member.username}
        </p>
      </div>

      <div className="friend-streak-points-container">
        <PointsStreak highscoreStreak={member.highscoreStreak} imageType={rankClass === "default" ? "streakNo" : "streakWhiteBorder" } />
        <PointsStreak highscoreStreak={member.currentPoints} currentLevelName={currentLevelName} />
      </div>
    </div>
  )
}

export default LeaderboardMember