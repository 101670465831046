import React, { createContext, useContext, ReactNode } from 'react';

import { useAnalytics } from '../Hooks/useAnalytics';

interface AnalyticsContextType {
  logAnalyticsEvent: (eventName: string, eventParams?: { [key: string]: any }) => void;
}

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(undefined);

export const AnalyticsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { logAnalyticsEvent } = useAnalytics();

  return (
    <AnalyticsContext.Provider value={{ logAnalyticsEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalyticsContext must be used within an AnalyticsProvider');
  }
  return context;
};