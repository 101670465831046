import React from 'react'

import "./GameAdminBar.css"

interface GameAdminBarProps {
  nextQuiz: () => void,
  prevQuiz: () => void,
  deleteQuiz: () => void,
}

const GameAdminBar: React.FC<GameAdminBarProps> = ({ nextQuiz, prevQuiz, deleteQuiz }) => {

  return (
    <div className='GameAdminBar'>
      <button 
        className='adminButton'
        onClick={prevQuiz}
      >
        Prev
      </button>

      <button 
        className='adminButton delete'
        onClick={deleteQuiz}
      >
        Delete
      </button>

      <button 
        className='adminButton'
        onClick={nextQuiz}
      >
        Next
      </button>
    </div>
  )
}

export default GameAdminBar