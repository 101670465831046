import React, {useState} from 'react'

import './DifficultyChoice.css'

import { GameDifficultyType } from '../../../../types/GameDifficultyType'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface DifficultyChoiceProps {
  gameDifficulty: GameDifficultyType,
  value: GameDifficultyType,
  label: string,
  disabled: boolean,
}

const DifficultyChoice: React.FC<DifficultyChoiceProps> = ({ gameDifficulty, value, label }) => {

  const [showIconModal, setShowIconModal] = useState<boolean>(false);

  return (
    <div
      key={value}
      className={`difficulty-button ${gameDifficulty === value ? `active ${label}` : ''}`}
    >
      {label}
      <button 
        className='info-button' 
        onMouseEnter={() => setShowIconModal(true)}
        onMouseLeave={() => setShowIconModal(false)}
      >
        <InfoOutlinedIcon className='icon' sx={{ fontSize: 16, color: 'white' }} />
      </button>
      {showIconModal && (
        <div className="iconModal">
          <p>Difficulty is based on your progress.</p>
          <p>Level 1,2,3 are Easy, 4,5,6,7 are Medium, and 8,9,10+ are Hard.</p>
          <p>Easy gives you only 2 choices, Medium 3 choices, and Hard 4 choices.</p>
        </div>
      )}

    </div>
  )
}

export default DifficultyChoice