import React, {useEffect, useState} from 'react'

import "./Notifications.css"

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import NotificationItem from './NotificationItem/NotificationItem';

import { useAuth } from '../../../Context/AuthContext';

import { changeAllNotificationsToRead, hasUserReadGlobalNotification, listenToNotificationsGlobal, listenToNotificationsUser, markGlobalNotificationAsRead } from '../../../services/firestoreService';

import { Notification } from '../../../types/NotificationType';

const Notifications: React.FC = () => {
  const { user } = useAuth()

  const [showNotificationsModal, setShowNotificationsModal] = useState<boolean>(false)
  const [userNotifications, setUserNotifications] = useState<Notification[]>([]);
  const [globalNotifications, setGlobalNotifications] = useState<Notification[]>([]);
  const [combinedNotifications, setCombinedNotifications] = useState<Notification[]>([]);

  const [newNotificationsCount, setNewNotificationsCount] = useState<number>(0)

  useEffect(() => {
    let unsubscribeUser: any;
    let unsubscribeGlobal: any;
  
    if (user?.uid) {
      unsubscribeUser = listenToNotificationsUser(user?.uid, setUserNotifications);
      unsubscribeGlobal = listenToNotificationsGlobal(setGlobalNotifications);
    }
  
    return () => {
      if (unsubscribeUser) unsubscribeUser();
      if (unsubscribeGlobal) unsubscribeGlobal();
    };
  }, [user]);

  useEffect(() => {
    const combined = [...userNotifications, ...globalNotifications].sort(
      (a, b) => (b.timestamp as any) - (a.timestamp as any)
    );
    setCombinedNotifications(combined);
  }, [userNotifications, globalNotifications]);
  
  useEffect(() => {
    const calculateUnreadCount = async () => {
      let unreadUserNotifications = userNotifications.filter(
        (notification) => notification.status === 'unread'
      ).length;
  
      let unreadGlobalCount = 0;
      for (const globalNotification of globalNotifications) {
        const hasRead = await hasUserReadGlobalNotification(user?.uid, globalNotification.id);
        if (!hasRead) {
          unreadGlobalCount++;
        }
      }
  
      setNewNotificationsCount(unreadUserNotifications + unreadGlobalCount);
    };
  
    if (user) {
      calculateUnreadCount();
    }
  }, [user, userNotifications, globalNotifications, showNotificationsModal]);
  
  const toggleNotificationModal = () => {
    setShowNotificationsModal((current) => !current);
    changeAllNotificationsToRead(user?.uid || '');
    
    if (user?.uid && globalNotifications.length > 0) {
      globalNotifications.forEach((notification) => {
        markGlobalNotificationAsRead(user.uid, notification.id);
      });
    }
  };

  return (
    <div className="Notifications">
      <button className='notification-button' onClick={toggleNotificationModal}>
        <NotificationsNoneIcon sx={{ fontSize: 24 }} />
        {newNotificationsCount > 0 && (
          <span className='new-notifs-count'>{newNotificationsCount}</span>
        )}
      </button>

      {showNotificationsModal && (
        <div className="notifications-modal">
          {combinedNotifications.length === 0 ? (
            <p className='no-notifications'>No notifications</p>
          ) : (
            combinedNotifications.map(notification => (
              <NotificationItem key={notification.id} notification={notification} userId={user?.uid || ""} />
            ))
          )}
        </div>
      )}
    </div>
  )
}

export default Notifications