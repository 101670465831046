import { useState, useEffect } from 'react';

import { increaseCorrectGuess, increaseWrongGuess, fetchGamemodeStats, updateGamemodePoints, updateGamemodeHighStreak } from '../services/firestoreService';

import { POINTS_CONFIG } from '../config/pointsConfig';

import { GameModeType } from '../types/GameModeType';
import { GameDifficultyType } from '../types/GameDifficultyType';

const levels = [0, 50, 150, 300, 500, 1000, 1750, 2500, 3500, 5000];
const levelNames = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export const usePointsSystem = ( userId: string, gameMode: GameModeType, initialSkips: number, setGameDifficulty: (gameDifficulty: GameDifficultyType) => void) => {
  const [currentPoints, setCurrentPoints] = useState(0);
  const [gameStreak, setGameStreak] = useState(0);
  const [highscoreStreak, setHighscoreStreak] = useState(0);
  const [currentLevelName, setCurrentLevelName] = useState('1');
  const [previousLevelPoints, setPreviousLevelPoints] = useState(0);
  const [nextLevelPoints, setNextLevelPoints] = useState(levels[1]);

  const [lastAnswer, setLastAnswer] = useState<boolean | null>(null);
  const [skipCount, setSkipCount] = useState<number>(initialSkips);
  const [currentMultiplier, setCurrentMultiplier] = useState<number>(1);

  useEffect(() => {
    if (userId && gameMode) {
      const fetchStats = async () => {
        const stats = await fetchGamemodeStats(userId, gameMode);
        setCurrentPoints(stats.points || 0);
        setHighscoreStreak(stats.highscoreStreak || 0);
        updateLevel(stats.points || 0);
      };
      fetchStats();
    }
  }, [userId, gameMode, currentPoints]);

  useEffect(() => {
    const levelNumber = parseInt(currentLevelName, 10);

    if (isNaN(levelNumber)) {
      console.error('Invalid currentLevelName:', currentLevelName);
      return;
    }

    if (levelNumber >= 1 && levelNumber <= 3) {
      setGameDifficulty(GameDifficultyType.Easy);
    } else if (levelNumber >= 4 && levelNumber <= 7) {
      setGameDifficulty(GameDifficultyType.Medium);
    } else {
      setGameDifficulty(GameDifficultyType.Hard);
    }
  }, [gameMode, currentLevelName, setGameDifficulty]);

  const updateLevel = (points: number) => {
    for (let i = levels.length - 1; i >= 0; i--) {
      if (points >= levels[i]) {
        setCurrentLevelName(levelNames[i]);
        setPreviousLevelPoints(levels[i]);
        setNextLevelPoints(levels[i + 1] || levels[i]);
        break;
      }
    }
  };


  const handleCorrectAnswer = (quizId : string, difficulty: string) => {
    increaseCorrectGuess(quizId)
    const config = POINTS_CONFIG[difficulty];

    const newMultiplier = currentMultiplier + config.multiplier;
    const multipliedPoints = Math.round(currentPoints + (config.basePointsWin * newMultiplier));

    setCurrentMultiplier(newMultiplier)
    updateGamemodePoints(userId, gameMode, multipliedPoints)
    setCurrentPoints(multipliedPoints);
    setGameStreak(streak => streak + 1);
    setLastAnswer(true)
    handleUpdateHighScoreStreak(gameStreak)
  };

  const handleIncorrectAnswer = (quizId : string, difficulty: string) => {
    increaseWrongGuess(quizId)

    const config = POINTS_CONFIG[difficulty];

    setCurrentPoints((current: number) => current - config.basePointsLose);
    updateGamemodePoints(userId, gameMode, currentPoints - config.basePointsLose)

    handleUpdateHighScoreStreak(gameStreak)
    setCurrentMultiplier(1)
    setGameStreak(0);
    setSkipCount(initialSkips);
    setLastAnswer(false)
  };

  const handleChangeInGamemodeDifficulty = () => {
    handleUpdateHighScoreStreak(gameStreak)
    setCurrentMultiplier(1)
    setGameStreak(0);
    setSkipCount(initialSkips);
  }

  const handleUpdateHighScoreStreak = (newStreak: number) => {
    if (newStreak > highscoreStreak) {
      setHighscoreStreak(newStreak);
      updateGamemodeHighStreak(userId, gameMode, newStreak)
    }
  };

  const handleSkipCountUsed = () => {
    setSkipCount(current => current - 1);
  }
  
  return {
    currentPoints,
    previousLevelPoints,
    nextLevelPoints,
    currentLevelName,
    gameStreak,
    highscoreStreak,
    lastAnswer,
    skipCount,
    handleSkipCountUsed,
    handleCorrectAnswer,
    handleIncorrectAnswer,
    handleChangeInGamemodeDifficulty,
  };
};
