import { useEffect, useState } from 'react';
import { Analytics, logEvent } from 'firebase/analytics';
import { analytics } from '../firebase/firebase-config';

export const useAnalytics = () => {
  const [analyticsInstance, setAnalyticsInstance] = useState<Analytics | null>(null);

  useEffect(() => {
    setAnalyticsInstance(analytics);
  }, []);

  const logAnalyticsEvent = (eventName: string, eventParams?: { [key: string]: any }) => {
    if (analyticsInstance) {
      logEvent(analyticsInstance, eventName, eventParams);
    } else {
      console.warn('Analytics not initialized');
    }
  };

  return { logAnalyticsEvent };
};