import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { 
  User as FirebaseUser,
  GoogleAuthProvider, 
  onAuthStateChanged, 
  signInAnonymously,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signOut as firebaseSignOut,
  linkWithPopup,
  linkWithCredential,
  signInWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';

import { auth } from '../firebase/firebase-config';
import { fetchOrCreateUser, updateUserLoginStats, updateUserTypeToAuthenticated, fetchUserStatistics, updateFirestoreUsername, deleteUserData, updateUserEmail } from '../services/firestoreService';
import { GameStateType } from '../types/GameStateType';
import { useGame } from './GameContext';
import { useAlert } from './AlertContext';

interface User {
  uid: string;
  email: string | null;
  username: string;
  userType: string;
  subscription: string;
  role: string;
  currentPoints: number;
  highscoreStreak: number;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signInWithGoogle: () => Promise<void>;
  signInWithEmailPassword: (email: string, password: string) => Promise<void>;
  createUserWithEmailPassword: (email: string, password: string) => Promise<void>;
  signOut: (callback?: () => void) => Promise<void>;
  updateUserState: (user: FirebaseUser | User) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { showAlert } = useAlert()
  const { setGameState, gameMode } = useGame();

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [anonymousSignInProgress, setAnonymousSignInProgress] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
    return () => unsubscribe();
  }, []);

  const handleAuthStateChange = async (firebaseUser: FirebaseUser | null) => {
    if (firebaseUser) {
      await updateUserState(firebaseUser);
    } else if (!anonymousSignInProgress) {
      setAnonymousSignInProgress(true);
      await signInAnonymously(auth);
    }
    setLoading(false);
  };

  const updateUserState = async (updatedUser: FirebaseUser | User) => {
    let newUser: User;

    if ('uid' in updatedUser && 'email' in updatedUser && 'username' in updatedUser) {
      newUser = updatedUser as User;
    } else {
      const { uid, email, displayName } = updatedUser as FirebaseUser;

      const { username, userType, subscription, role } = await fetchOrCreateUser(uid, email || "", displayName);
      const { currentPoints, highscoreStreak } = await fetchUserStatistics(uid, gameMode);

      newUser = {
        uid,
        email,
        username,
        userType,
        subscription,
        role,
        currentPoints,
        highscoreStreak,
      }
    }

    setUser(newUser);
    await updateUserLoginStats(newUser.uid);
    
    if ('username' in updatedUser) {
      await updateFirestoreUsername(newUser.uid, newUser.username, showAlert);
    }

    if (newUser.email && newUser.userType === "anonymous") {
      await updateUserTypeToAuthenticated(newUser.uid);
      await updateUserEmail(newUser.uid, newUser.email);

      newUser.userType = "authenticated";
      setUser({ ...newUser });
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const previousUid = auth.currentUser?.uid || null;

    try {
      if (auth.currentUser?.isAnonymous) {
        const result = await linkWithPopup(auth.currentUser, provider);
        await updateUserState(result.user);
      }
    } catch (error: any) {
      console.error("Google sign-in/linking error:", error);
      if (error.code === 'auth/credential-already-in-use') {
        const credential = GoogleAuthProvider.credentialFromError(error);
        if (credential) {
          try {
            const existingUserCredential = await signInWithCredential(auth, credential);
            await updateUserState(existingUserCredential.user);

            if (previousUid && previousUid !== auth.currentUser?.uid) {
              await deleteUserData(previousUid)
            }
          } catch (signInError: any) {
            console.error("Error signing in existing user after credential already in use:", signInError);
            alert('Failed to sign in with Google.');
            throw signInError;
          }
        } else {
          alert('Failed to retrieve Google credential.');
        }
      } else {
        alert('Failed to sign in with Google.');
      }
      throw error;
    }
  };

  const signInWithEmailPassword = async (email: string, password: string) => {
    const previousUid = auth.currentUser?.uid || null;
  
    try {
      const userCredential = await firebaseSignInWithEmailAndPassword(auth, email, password);
      await updateUserState(userCredential.user);
      alert('Successfully signed in with Email/Password.');
    } catch (error: any) {
      console.error("Email/Password sign-in error:", error);
      if (error.code === 'auth/user-not-found') {
        alert('No account found with this email.');
      } else if (error.code === 'auth/wrong-password') {
        alert('Incorrect password.');
      } else {
        alert('Failed to sign in with Email/Password.');
      }
      throw error;
    }
  
    if (previousUid && previousUid !== auth.currentUser?.uid) {
      try {
        await deleteUserData(previousUid);
      } catch (deleteError) {
        console.error(`Error deleting previous user data for userId ${previousUid}:`, deleteError);
      }
    }
  };

  const createUserWithEmailPassword = async (email: string, password: string) => {
    try {
      if (auth.currentUser?.isAnonymous) {
        const credential = EmailAuthProvider.credential(email, password);
        const result = await linkWithCredential(auth.currentUser, credential);
        await updateUserState(result.user);
      }
    } catch (error: any) {
      console.error("Error creating/linking user with Email and Password:", error);
      if (error.code === 'auth/email-already-in-use') {
        alert('This email is already associated with another account.');
      } else if (error.code === 'auth/invalid-email') {
        alert('The email address is not valid.');
      } else {
        alert('Failed to create or link account. Please try again.');
      }
      throw error;
    }
  };

  const signOut = async () => {
    await firebaseSignOut(auth);
    setUser(null);
    setGameState(GameStateType.Instructions);
    setAnonymousSignInProgress(false);
    await signInAnonymously(auth);
  };

  const contextValue: AuthContextType = {
    user,
    loading,
    signInWithGoogle,
    signInWithEmailPassword,
    createUserWithEmailPassword,
    signOut,
    updateUserState,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};