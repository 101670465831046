import React, { useState } from 'react'

import './MessageSubmitModal.css'

import BasicButton from '../BasicButton/BasicButton'

import SendIcon from '@mui/icons-material/Send';

import { useAuth } from '../../Context/AuthContext';
import { useAlert } from '../../Context/AlertContext';

import { Quiz } from '../../types/Quiz';

import { submitReportFirebase } from '../../services/firestoreService';

interface MessageSubmitModalProps {
  handleToggleModal: () => void;
  currentQuiz: Quiz;
}

const MessageSubmitModal: React.FC<MessageSubmitModalProps> = ({ handleToggleModal, currentQuiz }) => {
  const { showAlert } = useAlert()
  const { user } = useAuth()
  
  const [email, setEmail] = useState(user?.email ? user?.email : "")
  const [reportMessage, setReportMessage] = useState("")

  const handleSubmitReport = () => {
    handleToggleModal()
    submitReportFirebase(
      user?.uid || "", 
      user?.username || "", 
      email, 
      currentQuiz.region, 
      currentQuiz.country, 
      currentQuiz.id, 
      currentQuiz.imageUrl, 
      reportMessage
    )
    showAlert("Report submitted! We'll get back to you soon. Thank you for your help.", "green")
  }

  const userIsNotAuthenticated = user?.userType === "anonymous"

  return (
    <div className='MessageSubmitModal'>
      <h2>Report Image</h2>
      {userIsNotAuthenticated && (
        <div className="sectionContainer">
          <h3>Your email (optional)</h3>
          <input
            type='text'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='your@email.com'
          />
        </div>
      )}

      <div className="sectionContainer">
        <h3>Reason for report (optional)</h3>
        <textarea
          value={reportMessage}
          onChange={(e) => setReportMessage(e.target.value)}
          placeholder='Bad image/crop/no image/incorrect etc...'
          rows={4}
        />
      </div>

      <BasicButton 
        text='Submit' 
        icon={<SendIcon sx={{ fontSize: 18 }}/>}
        onClick={handleSubmitReport} 
      />
    </div>
  )
}

export default MessageSubmitModal