import React, {useState, useEffect} from 'react'

import './PointsStreak.css'

import StreakNo from '../../../../../assets/icons/Streak-white-bg.png'
import StreakWhiteBorder from '../../../../../assets/icons/Streak.png'

import { levelImageMap } from '../../../../../config/levelImageData'

interface PointsStreakProps {
  highscoreStreak: number,
  imageType?: string,
  maxxed?: boolean,
  currentLevelName?: string,
}

const PointsStreak: React.FC<PointsStreakProps> = ({ highscoreStreak, imageType, maxxed, currentLevelName }) => {
  const [displayedStreak, setDisplayedStreak] = useState<number>(highscoreStreak);

  useEffect(() => {
    // Reset displayedStreak if highscoreStreak changes
    if (highscoreStreak !== displayedStreak) {
      const increment = highscoreStreak > displayedStreak ? 1 : -1;
      const updateCounter = () => {
        setDisplayedStreak((prev) => prev + increment);
      };

      if (displayedStreak !== highscoreStreak) {
        const timeout = setTimeout(updateCounter, 40);
        return () => clearTimeout(timeout);
      }
    }
  }, [highscoreStreak, displayedStreak]);

  // Select the appropriate image
  let selectedImage;
  let selectedImageAlt;

  switch (imageType) {
    case 'streakNo':
      selectedImage = StreakNo;
      selectedImageAlt = "streak";
      break;
    case 'streakWhiteBorder':
      selectedImage = StreakWhiteBorder;
      selectedImageAlt = "streak";
      break;
    default:
      selectedImage = StreakNo;
      selectedImageAlt = "streak";
      break;
  }

  return (
    <div className='PointsStreak'>
      <p className={`leaderboard-points ${maxxed ? "maxxed" : ""}`}>{maxxed ? displayedStreak : highscoreStreak}</p>
      {currentLevelName ? (
        <img src={levelImageMap[currentLevelName]} alt="" />
      ) : (
        <img src={selectedImage} alt={selectedImageAlt} />
      )}

      {currentLevelName ? (
        <p className='levelInfo'>Level {currentLevelName}</p>
      ): (
        <p className='levelInfo'>Streak</p>
      )}
    </div>
  );
};

export default PointsStreak