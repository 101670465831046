import React from 'react'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import './AlertElement.css'

interface AlertElementProps {
  message: string;
  status: 'red' | 'green' | 'blue' | null;
}

const AlertElement: React.FC<AlertElementProps> = ({ message, status }) => {
  return (
    <div className={`AlertElement ${status}`}>
      {status === 'green' ? (
        <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 20, color: '#fff' }} />
      ) : status === 'red' ? (
        <ErrorOutlineOutlinedIcon sx={{ fontSize: 20, color: '#fff' }} />
      ) : (
        <InfoOutlinedIcon sx={{ fontSize: 20, color: '#fff' }} />
      )}

      {message}
    </div>
  )
}

export default AlertElement