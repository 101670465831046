import React, { useEffect, useState } from 'react'

import "./Game.css"

import { useGame } from '../../../Context/GameContext'
import { GameStateType } from '../../../types/GameStateType'

import Instructions from './Instructions/Instructions'
import Playing from './Playing/Playing'
import StartGame from './StartGame/StartGame'

const Game: React.FC = () => {
  const { gameState, setGameState, gameDifficulty, gameMode } = useGame();

  const [fullWidth, setFullWidth] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (gameState === GameStateType.Playing && !isMobile) {
      setFullWidth(true)
    } else {
      setFullWidth(false)
    }
  }, [gameState])

  return (
    <div className={`Game ${(fullWidth) ? "fullWidthGame" : ""}`}>
      {gameState === GameStateType.Instructions && (
        <Instructions setGameState={setGameState} />
      )}

      {gameState === GameStateType.Start && (
        <StartGame setGameState={setGameState} gameDifficulty={gameDifficulty} gameMode={gameMode} />
      )}

      {(gameState === GameStateType.Playing || gameState === GameStateType.End) && (
        <Playing />
      )}
    </div>
  )
}

export default Game